<template>
    <div>
      <div class="all_context">
         <div class="all_context_flex">
          <div class="storeCenter_item_top_left">
            <div class="banner_box">
              <img v-if="headData.coverImage" :src="headData.coverImage" alt="" />
              
            </div>
            <div class="storeCenter_item_top_left_flex">
              <div style="display: flex; align-items: center">
                <img
                  width="16"
                  height="16"
                  v-if="headData.reportType == '报告合集'"
                  src="https://ginseng-wisdom-1300257084.cos.ap-nanjing.myqcloud.com/system_img/%E5%8E%8B%E7%BC%A9%E5%8C%85%201.png"
                  alt=""
                />
                <img
                  width="16"
                  height="16"
                  v-else
                  src="https://ginseng-wisdom-1300257084.cos.ap-nanjing.myqcloud.com/system_img/PDF%20%281%29%201.png"
                  alt=""
                />
                <h5  class="h5_name">
                  {{ headData.reportName }}
                </h5>
                </div>

              <!-- <div>{{ item.sponsor }}</div> -->
              <div v-if="headData.releaseMechanism"  class="releaseMechanism">
                出版机构：{{ headData.releaseMechanism }}
              </div>
              <!-- <div v-if="headData.releaseTime" class="releaseMechanism">
                发布日期：{{ headData.releaseTime }}
              </div> -->
              <div class="h5_names" >
                {{ headData.reportName }}
              </div>
            </div>
          </div>
            <div class="right">
            <div class="total_num">
              <span>{{ userSharenum||0 }}</span>
            </div>
            <div>
              <span>下载人数</span>
            </div>
          </div>
         </div>
  
        <div class="context">
          <div class="state">
            <div class="left">
             
            </div>
  
            <div class="right">
              <div class="seach_box">
                <el-input
                  placeholder="请输入企业全称"
                  v-model="query.keys"
                  class="input-with-select"
                >
                  <el-button class="seach" slot="append" @click="search()">搜索</el-button>
                </el-input>
              </div>
            </div>
          </div>
  
          <div class="table">
            <el-table
              ref="multipleTable"
              :data="tableData"
              tooltip-effect="dark"
              style="width: 100%"
              v-loading="loading"
              element-loading-spinner="el-icon-loading"
              element-loading-text="加载中..."
           
              :header-cell-style="{ background: '#F8F9FB', color: '#595959' }"
            >
              <el-table-column width="100" prop="id" label="编号" align="center"> </el-table-column>
              <el-table-column width="140" label="微信头像" align="center">
                <template v-slot="{ row }">
                  <img v-if="row.headImgUrl" width="30" height="30" :src="row.headImgUrl" alt="" />
                  <img
                    v-else
                    width="30" 
                    height="30"
                    src="https://ginseng-wisdom-1300257084.cos.ap-nanjing.myqcloud.com/system_img_dev/202302061802.png"
                    class="img"
                    alt=""
                  />
                </template>
              </el-table-column>
              <el-table-column width="120" prop="nickName" label="微信昵称" align="center" >
              </el-table-column>
              <el-table-column  prop="realName" label="姓名" align="center"> </el-table-column>
  
              <el-table-column  label="性别" align="center">
                <template v-slot="{ row }">
                  <div class="sex" v-if="row.sex == '1'">男</div>
                  <div class="sex" v-else>女</div>
                </template>
              </el-table-column>
  
              <el-table-column width="120" prop="position" label="职位" align="center"> </el-table-column>
              <el-table-column prop="phone" label="手机号"  align="center">
              </el-table-column>
              <el-table-column label="所在城市" align="center">
                <template v-slot="{ row }">
                  <span>{{ row.city }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="companyFullName" label="企业全称" align="center">
                <template v-slot="{ row }">
                  <span>{{ row.companyFullName }}</span>
                </template>
              </el-table-column>
  
             
              <el-table-column
                prop="createTime"
                label="下载时间"
                sortable
                width="200"
                align="center"
              ></el-table-column>
            </el-table>
  
            <div class="Pagination">
              <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="query.size"
                :page-sizes="[10, 30, 50]"
                :page-size="query.pageNum"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total"
              >
              </el-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  <script>
  import { listUserDownPage, selectById,userDown } from '@/api/report.js'
  export default {
    name: 'shareCount',
    computed: {
      activeId() {
        return this.$route.query.id
      }
    },
    data() {
      //这里存放数据
      return {
        headData: {}, ///头部信息
        // 表格数据
        tableData: [],
        loading: false,
        // 分页总条数
        total: 0,
        ids: [],
        // 表格发送数据
        query: {
            reportId: '',
          keys: '', //名/企业名称
  
          pageNum: 1,
          pageSize: 10,
          orders: '1' //排序排序 默认传1 2->时间降序;3->时间升序
        },
        userSharenum:0,//分享查看数
      }
    },
  
    created() {
      this.getHeadData()
      this.search()
      this.userDown()
    },
    //方法集合
    methods: {
       //分享查看数
  async  userDown(){
    const query={
        reportId:this.activeId
    }
    const res=await userDown(query)
    if(res.code==200){
      this.userSharenum=res.data
    }
    },
      handleSizeChange(val) {
        this.query.pageSize = val
        this.search()
      },
      handleCurrentChange(val) {
        this.query.pageNum = val
        this.search()
      },
  
      // 头部数据
      async getHeadData() {
        const  res = await selectById({
          id: this.activeId
        })
        if (res.code == 200) {
          this.headData = res.data
        }
      },
  
      // table表格按钮的选中数据
      handleSelectionChange(selection) {
        this.ids = selection.map((item) => item.id)
      },
      async search() {
        this.query.reportId = this.activeId
        this.loading = true
        const  res  = await listUserDownPage(this.query)
  
        if (res.code == 200) {
          this.tableData = res.data.list
          this.total = res.data.total
          this.loading = false
        }
      }
    }
  }
  </script>
  <style scoped lang="less">
  .all_context {
    padding: 16px 0px 0 0px;
    .all_context_flex{
      display: flex;
      background-color: #ffffff;
      padding-right: 50px;
      border-radius: 8px;
      padding: 20px;
      position: relative;
      margin-bottom: 20px;
      .right {
          position: absolute;
          right: 100px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .total_num {
              margin-bottom: 10px;
              font-size: 30px;
              font-weight: 550;
              color: #ff7d18;
            }
    }
  }
  .storeCenter_item_top_left {
        display: flex;
        flex-direction: row;
        height: 107.73px;
        width: 450px;
        .banner_box {
          position: relative;
          width: 185.55px;
          height: 117.73px;
          .generalVersion {
            background: #448aff;
            border-radius: 2px;
            padding: 5px;
            font-family: 'PingFang SC';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            /* identical to box height */
            margin: 0 10px;
            color: #ffffff;
            position: absolute;
            left: 0px;
            top: 5px;
          }
          img {
            width: 185.55px;
            height: 117.73px;
            border-radius: 4px;
            margin-right: 14px;
          }
          .activity_state {
            position: absolute;
            font-family: 'PingFang SC';
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 34px;
            text-align: center;
            color: #ffffff;
            top: -10px;
            left: -9px;
            width: 78px;
            height: 34px;
            border-radius: 5px 15px 15px 0;
          }
          .not_started {
            background: #4e93fb;
          }
          .have_in_hand {
            background: #ff7d18;
          }
          .closed {
            background: #999999;
          }
        }

        .storeCenter_item_top_left_flex {
          margin-left: 10px;
          .h5_name {
            display: inline-block;
            margin: 0;
            font-size: 18px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            width: 200px;
            -webkit-box-orient: vertical;
            margin-left: 5px;
            cursor: pointer;
          }
          .h5_names {
            display: inline-block;
            margin: 0;
            font-size: 17px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            width: 320px;
            -webkit-box-orient: vertical;
            cursor: pointer;
          }
          .mark_box {
            display: flex;
            img {
              width: 14px;
              // height: 14px;
            }
            & > div {
              margin-left: 10px;
            }
          }
          .img_commer {
            display: flex;
            border: 1px solid #146aff;
            border-radius: 39px;
            .name {
              font-size: 12px;
              line-height: 20px;
              color: #146aff;
            }
            .color_img {
              background: #146aff;
              height: 20px;
              width: 25px;
              border-radius: 20px;
              position: relative;
              left: -10px;
              img {
                width: 13px;
                height: 13px;
                position: relative;
                left: 5px;
                top: 2px;
              }
            }
          }
          .img_commers {
            display: flex;
            border: 1px solid #ff9c00;
            border-radius: 39px;
            margin: 0px 10px;
            .name {
              font-size: 12px;
              line-height: 20px;
              color: #ff9c00;
            }
            .color_img {
              background: #ff9c00;
              height: 20px;
              width: 25px;
              border-radius: 20px;
              position: relative;
              left: -10px;
              img {
                width: 13px;
                height: 13px;
                position: relative;
                left: 5px;
                top: 2px;
              }
            }
          }

          h4 {
            color: #1f2d3d;
            font-size: 26px;
            font-weight: 500;
            margin: 0;
          }

          .storeCenter_item_top_left_flex_tag {
            padding-top: 10px;
            width: 100%;
            display: flex;
            flex-direction: row;
            align-content: center;

            margin-bottom: 1px;
            width: 500px;

            .hidden_label_box {
              width: 100%;
              display: flex;
              flex-direction: row;
              align-content: center;
              flex-wrap: wrap;
            }
            span {
              box-sizing: border-box;
              display: block;
              font-size: 14px;
              font-weight: 400;
              border-radius: 4px;
            }

            .office {
              border: 1px solid #559eff;
              color: #4e93fb;
              padding: 1px 6px;
              margin-right: 10px;
            }

            .support {
              border: 1px solid #fd523f;
              color: #fd523f;
              margin-left: 12px;
            }
          }

          .releaseMechanism {
            width: 450px;
            padding: 10px 0px;
            font-size: 16px;
          }
        }
      }
    .seach_box {
      display: flex;
      margin: 15px 0;
      align-items: flex-end;
      .input-with-select {
        width: 400px;
      }
  
      .seach {
        background-color: #448aff;
        color: #fff;
        border-radius: 0;
        border: 1px solid #448aff;
      }
    }
  
    .context {
      background-color: #ffffff;
      padding: 0 21px 0 30px;
      border-radius: 8px;
      .pass {
        color: #4e93fb;
        cursor: pointer;
      }
      .un_pass {
        color: #fd6161;
        cursor: pointer;
      }
      .state {
        height: 72px;
        border-bottom: 1px dashed #f3f3f3;
        display: flex;
        box-sizing: border-box;
        align-items: center;
        justify-content: space-between;
  
        .left {
          display: flex;
          box-sizing: border-box;
          align-items: center;
          .label {
            font-size: 14px;
            color: #7c7f8e;
          }
          .item {
            color: #333333;
            font-size: 14px;
            cursor: pointer;
            padding: 15px 20px;
          }
        }
        .right {
          display: flex;
          padding-right: 25px;
          f .icon {
            width: 20px;
            height: 20px;
  
            img {
              width: 100%;
              height: 100%;
            }
          }
          .tex {
            margin-left: 7px;
            color: #595959;
          }
          .time {
            color: #333333;
          }
        }
        /deep/.el-radio {
          margin-right: 0;
        }
      }
      .time_search {
        height: 80px;
        display: flex;
        align-items: center;
        padding-right: 10px;
        justify-content: space-between;
        position: relative;
        .time {
          display: flex;
          box-sizing: border-box;
          align-items: center;
          position: absolute;
          left: 0;
  
          .label {
            font-size: 14px;
            color: #7c7f8e;
          }
          // .item {
          //   color: #333333;
          //   font-size: 14px;
          //   cursor: pointer;
          //   padding: 15px 20px;
          // }
        }
        .search {
          display: flex;
          position: absolute;
          right: 0;
          .search_input {
            width: 430px;
          }
          .search_div {
            width: 74px;
            height: 40px;
            background-color: #448aff;
            color: #fff;
            border-radius: 2px;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 14px;
            transform: translateX(-2px);
          }
        }
      }
  
      .table {
        .imgbox {
          display: flex;
          justify-content: center;
  
          .img {
            width: 34px;
            height: 34px;
  
            img {
              width: 100%;
              height: 100%;
            }
          }
        }
  
      
        .correlationBox {
          display: flex;
          align-items: center;
          justify-content: center;
          .correlation {
            background-color: #fd6161;
            border-radius: 2px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 55px;
            height: 24px;
            color: #fff;
            font-size: 12px;
          }
        }
  
        .enterpriseIntro {
          // width: 100%;
          // height: 100%;
          width: 376px;
          overflow: hidden; //超出隐藏
          text-overflow: ellipsis; //溢出用省略号显示
          display: -webkit-box; // 将对象作为弹性伸缩盒子模型显示。
          // 控制行数
          -webkit-line-clamp: 2; //超出两行隐藏
          -webkit-box-orient: vertical; // 从上到下垂直排列子元素
          color: #333333;
          position: relative;
        }
        .enterpriseIntroShow {
          position: absolute;
          // right: 0%;
          // bottom: -23%;
          border-radius: 8px;
          // transform: translateX(50%);
          width: 376px;
          padding: 10px 7px 8px 13px;
          background-color: #fff;
          z-index: 999;
          font-size: 14px;
          font-weight: 400;
          color: #333333;
        }
  
        /deep/.el-table__body-wrapper {
          overflow: visible;
        }
        /deep/.el-table {
          overflow: visible;
        }
      }
      .Pagination {
        width: 100%;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        padding: 50px 0 50px 0;
      }
    }
  }
  
  .add_select {
    .remove_pool {
      box-sizing: border-box;
      width: 348px;
      height: 150px;
      background-color: #fff;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 4px;
      box-shadow: 0px 0px 2px #d4d4d4;
      display: flex;
      flex-direction: column;
      // justify-content: center;
      padding: 42px 0 20px 0;
      align-items: center;
      .el-icon-close {
        position: absolute;
        right: 11px;
        top: 11px;
        color: #b6b6b4;
        font-size: 18px;
        cursor: pointer;
      }
      .text {
        margin-bottom: 23px;
      }
      /deep/.el-button {
        width: 112px;
        height: 40px;
      }
    }
    /deep/.el-dialog {
      margin-top: 40vh !important;
    }
  }
  
  .correlation {
    /deep/.el-dialog {
      border-radius: 4px;
      position: relative;
    }
    /deep/.el-dialog__body {
      padding: 28px 20px 16px 20px;
    }
    .correlationBox {
      .head {
        position: absolute;
        left: 0;
        top: 12px;
        // transform: translate(-20px, -50px);
        .title {
          border-left: 4px solid #448aff;
          padding-left: 15px;
          font-size: 18px;
          font-weight: 550;
          color: #151515;
        }
      }
      .context {
        .input {
          display: flex;
          justify-content: space-around;
          align-items: center;
          .label {
            position: relative;
            font-size: 14px;
            color: #333333;
            span {
              position: absolute;
              right: -9px;
              top: -2px;
              color: #f05252;
            }
          }
          /deep/.el-input {
            width: 322px;
          }
        }
        .button {
          width: 100%;
          display: flex;
          justify-content: center;
          .text {
            width: 64px;
            height: 36px;
            cursor: pointer;
            border-radius: 2px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            font-size: 14px;
            background-color: #448aff;
            margin-top: 31px;
          }
        }
      }
    }
  }
  </style>
  